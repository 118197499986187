<template>
    <header class="header fixed-top">
        <b-navbar class="p-0">
            <b-navbar-brand class="p-0 m-0 d-flex align-items-center collapsed-logo desktop-logo"
                            :class="[{ 'expanded-logo': sideBarExpandedWrapper }]">
                <div class="logo-wrapper">
                    <router-link to="/dashboard">
                        <img v-show="$store.getters['dashboard/getImage']('menu-logo.png')"
                             :src="$store.getters['dashboard/getImage']('menu-logo.png')"/>
                    </router-link>
                </div>
            </b-navbar-brand>
            <b-collapse id="nav-collapse" is-nav>
                <b-collapse id="nav-collapse" is-nav class="d-block">
                    <div class="mb-0 ml-5 bg-transparent small text-uppercase d-none d-lg-inline-flex p-0">
                        <h5 class="m-0 d-flex align-items-center" v-if="title">
                            {{ changeTitle(title) }}
                        </h5>
                        <b-button variant="outline-secondary secondary-border-light" class="mx-2"
                                  v-if="backVisibleState">
                            <i class="header-back-icon ri-arrow-left-s-line "
                               @click="$router.back()"></i>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                                  @click="$emit('new-button-click')" v-if="isNewButton">
                            <span class="d-flex">
                                <i class="ri-add-line mr-1"></i>
                                {{ $t("add") }}
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                                  @click="$emit('rank-button-click')" v-if="isRankButton">
                            <span>
                                <i class="ri-sort-desc top-plus-1 mr-1"></i>
                                {{ $t("ranking") }}
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                                  @click="$emit('import-button-click')" v-if="isImportButton">
                            <span class="d-flex">
                                <i class="ri-upload-line mr-1"></i>
                                {{ $t("import_button") }}
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2" @click="
                            otherButton.emit ? $emit(otherButton.emit) : ''
                            " v-if="otherButton.show">
                            <span class="d-flex">
                                <i :class="otherButton.icon
                                    ? otherButton.icon + ' mr-1'
                                    : ''
                                    " v-if="otherButton.icon"></i>
                                <span v-if="otherButton.title">{{
                                        otherButton.title
                                    }}</span>
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2" @click="
                            asalButton.emit ? $emit(asalButton.emit) : ''
                            " v-if="asalButton.show && selectedMenu == 'military_information'">
                            <span class="d-flex">
                                <i :class="asalButton.icon
                                    ? asalButton.icon + ' mr-1'
                                    : ''
                                    " v-if="asalButton.icon"></i>
                                <span v-if="asalButton.title">{{
                                        asalButton.title
                                    }}</span>
                            </span>
                        </b-button>
                        <b-dropdown variant="outline-secondary secondary-border-light" class="mr-2" v-if="isColumns">
                            <template #button-content>
                                <span class="d-flex">
                                    <i class="ri-layout-column-line mr-1"></i>
                                    {{ $t("columns") }}
                                </span>
                            </template>
                            <div class="checkbox-list width-300">
                                <div class="px-4 py-2">
                                    <slot name="columns"></slot>
                                </div>
                            </div>
                        </b-dropdown>
                        <b-button variant="outline-secondary secondary-border-light" class="mr-2"
                                  @click="filterDivStatusHide" v-if="isFilter">
                            <span>
                                <i class="ri-filter-3-line mr-1"></i>
                                {{ $t("filter") }}
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light w-40" class="mr-2"
                                  @click="$emit('expand-all')" v-if="isExpandAll">
                            <span class="d-flex">
                                <i class="ri-arrow-down-s-line font-size-20"></i>
                            </span>
                        </b-button>
                        <b-button variant="outline-secondary secondary-border-light w-40" class="mr-2"
                                  @click="$emit('collapse-all')" v-if="isCollapseAll">
                            <span class="d-flex">
                                <i class="ri-arrow-up-s-line font-size-20"></i>
                            </span>
                        </b-button>
                        <b-button variant="danger" class="mr-2" @click="$emit('move-cancel')" v-if="isCancelToMove">
                            <span class="d-flex">
                                <i class="ri-close-line font-size-20"></i>
                                {{ $t("cancel_to_move") }}
                            </span>
                        </b-button>
                        <b-dropdown variant="outline-secondary secondary-border-light" class="width-40 mr-2"
                                    v-if="actions && filteredActions.length > 0">
                            <b-dropdown-item v-for="(action, actionKey) in filteredActions" :key="'action' + actionKey"
                                             @click="action.callback">
                                <i :class="action.class" v-if="action.class"></i>
                                {{ action.text }}
                            </b-dropdown-item>
                        </b-dropdown>
                        <b-button variant="outline-secondary secondary-border-light" class="width-40 mr-2"
                                  @click="backButton" v-if="isBackButton">
                            <i class="ri-arrow-left-line"></i>
                        </b-button>
                    </div>
                    <b-navbar-nav class="ml-0 ml-lg-auto pl-4 pl-lg-0 py-4 pr-4 py-lg-5 pr-lg-5">
                        <!--
                        <b-button class="btn-40 d-none d-lg-flex" :class="{'btn-dark':nightMode}" variant="outline-secondary" @click="changeNightMode">
                            <i class="ri-moon-line left-plus-1 position-relative"></i>
                        </b-button>
                        -->
                        <div class="d-flex">
                            <b-button class="btn-40 d-block d-lg-none" variant="outline-secondary"
                                      @click="changeSideBarExpandedWrapper">
                                <i class="ri-menu-2-line"></i>
                            </b-button>

                            <!--
                            <b-dropdown id="dropdown-form"
                                        right
                                        ref="dropdown"
                                        class="ml-2 ml-sm-3 ml-lg-4 notify dropdown-mobile-left "
                                        variant="outline-secondary"
                                        no-caret>
                                <template #button-content>
                                    <i class="ri-notification-line"></i>
                                    <i class="ri-checkbox-blank-circle-fill ri-xs text-success position-absolute alert-dot"></i>
                                    <div class="dot bg-success position-absolute"></div>
                                </template>
                                <b-dropdown-item-button class="mb-1" v-for="(notification,key) in notifications" :key="key">
                                    <div class="d-flex">
                                        <i class="ri-checkbox-blank-circle-fill ri-xs mt-3px mr-3 text-success"></i>
                                        <div>
                                            <div class="ellipsis-2">{{ notification.message }}</div>
                                            <div class="text-right mt-2 text-muted">{{ notification.time }}</div>
                                        </div>
                                    </div>
                                </b-dropdown-item-button>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button class="text-center"><span class="text-uppercase small text-muted">Tüm Bildirimler</span>
                                </b-dropdown-item-button>
                            </b-dropdown>
                            -->
                        </div>

                        <b-navbar-brand class="p-0 m-0 d-flex align-items-center collapsed-logo d-lg-none">
                            <div class="logo-wrapper">
                                <router-link to="/dashboard">
                                    <img src="../../assets/img/umis-logo.png"/>
                                </router-link>
                            </div>
                        </b-navbar-brand>

                        <div class="d-flex">
                            <!--
                            <b-navbar-toggle target="nav-mobil-search"
                                             class="btn btn-40 d-block d-lg-none btn-outline-secondary">
                                <template>
                                    <i class="ri-search-line"></i>
                                </template>
                            </b-navbar-toggle>
                            -->
                            <b-button class="btn-40 d-lg-flex" variant="outline-secondary mr-2" @click="print"
                                      v-if="isPrint">

                                <i class="ri-printer-line"></i>
                            </b-button>
                            <div class="social-media-icons">
                            <span class="icons" v-for="(socialMedia,key) in socialMediaLinks" @click="goToSocialMedia(socialMedia.url)">
                              <img :src="socialMedia.svg" :alt="socialMedia.alt" :title="socialMedia.title"
                                   :key="key"
                                   v-if="socialMediaLinks.length"
                                   style="width: 25px; height: 25px;">
                            </span>
<!--                                <span class="icons">-->
<!--                              <img :src="require('@/assets/svg/facebook.svg')" alt="Facebook" title="Facebook"-->
<!--                                   style="width: 25px; height: 25px;">-->
<!--                            </span>-->
<!--                                <span class="icons">-->
<!--                              <img :src="require('@/assets/svg/twitter.svg')" alt="Twitter" title="Twitter" style="width: 25px; height: 25px;">-->
<!--                            </span>-->
<!--                                <span class="icons">-->
<!--                              <img :src="require('@/assets/svg/linkedln.svg')" alt="Linkedln" title="Linkedln" style="width: 25px; height: 25px;">-->
<!--                            </span>-->
<!--                             <span class="icons">-->
<!--                              <img :src="require('@/assets/svg/youtube.svg')" alt="Youtube" title="Youtube" style="width: 25px; height: 25px;">-->
<!--                            </span>-->
<!--                                <span class="icons">-->
<!--                              <img :src="require('@/assets/svg/tiktok.svg')" alt="Tiktok" title="Tiktok" style="width: 25px; height: 25px;">-->
<!--                            </span>-->

                            </div>
                            <!--                            <b-button class="btn-40 d-lg-flex mr-2" variant="outline-secondary">-->
                            <!--                                EN-->
                            <!--                            </b-button>-->
                            <b-button class="btn" variant="outline-secondary mr-2"
                                      v-if="user?.active_role?.name == 'OGR'">
                                <div class="d-flex flex-column">
                                    <span style="margin-bottom: -20px;font-weight: bold">{{ user.full_name }}</span>
                                    <span style="margin-top:-5px;font-weight: bold" v-if="locale == 'tr'"> {{
                                            user.active_student_program.program_name
                                        }}</span>
                                    <span style="margin-top:-5px;font-weight: bold" v-else> {{
                                            user.active_student_program.program_name_en
                                        }}</span>
                                </div>
                            </b-button>

                            <b-button class="btn p-1 header-user-info-btn d-none d-md-block"
                                      variant="outline-secondary mr-2" v-else>
                                {{ user.full_name }} {{ getLocaleText(user.active_role, 'explanation') }}
                            </b-button>
                            <b-dropdown id="dropdown-form" ref="dropdown" variant="link" class="no-btn" right no-caret>
                                <template #button-content>
                                    <div class="wh-40-img rounded bg-cover-center" :style="{
                                        'background-image':
                                            'url(' + photo + ')',
                                    }" v-if="photo"></div>
                                    <div class="wh-40-img rounded bg-cover-center" v-bind:style="{
                                        'background-image':
                                            'url(' +
                                            require('@/assets/img/no-photo-2.jpg') +
                                            ')',
                                    }" v-else></div>
                                </template>

                                <b-dropdown-item to="/my-profile" v-if="isStudent">
                                    <i class="ri-user-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t("personal_info") }}
                                </b-dropdown-item>

                                <!--
                                <b-dropdown-item>
                                    <i class="ri-arrow-left-right-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t('switch_user') }}
                                </b-dropdown-item>
                                -->

                                <!-- Roles -->
                                <b-dropdown-item v-if="roles && roles.length > 1"
                                                 @click="$bvModal.show('otherIdentiesModal')">
                                    <i class="ri-profile-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t("other_identies") }}
                                </b-dropdown-item>

                                <!-- Programs -->
                                <b-dropdown-item v-if="programs && programs.length > 1"
                                                 @click="$bvModal.show('otherProgramsModal')">
                                    <i class="ri-profile-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t("other_programs") }}
                                </b-dropdown-item>

                                <b-dropdown-item @click="changePasswordModalShow()">
                                    <i class="ri-key-line mr-3 top-minus-1 align-middle text-muted"></i>
                                    {{ $t("change_your_password") }}
                                </b-dropdown-item>

                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item to="/logout">
                                    <span class="text-danger">
                                        <i class="ri-logout-circle-line mr-3 top-minus-1 align-middle"></i>
                                        {{ $t("logout") }}
                                    </span>
                                </b-dropdown-item>
                            </b-dropdown>
                        </div>
                    </b-navbar-nav>
                </b-collapse>
            </b-collapse>
        </b-navbar>
        <CommonModal id="otherIdentiesModal" size="md">
            <template v-slot:CommonModalTitle>
                <div>{{ $t("other_identies") }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="list-group">
                    <template v-for="(role, key) in roles">
                        <a href="#" class="list-group-item list-group-item-action" :class="role.name == activeRole.name &&
                            role.faculty_code == activeRole.faculty_code &&
                            role.department_code ==
                            activeRole.department_code &&
                            role.program_code == activeRole.program_code
                            ? 'active'
                            : ''
                            " @click="changeRole(role)" v-if="roles && roles.length > 1" :key="key">
                            {{ role.explanation }}
                        </a>
                    </template>
                </div>
            </template>
        </CommonModal>
        <CommonModal id="otherProgramsModal" size="md">
            <template v-slot:CommonModalTitle>
                <div>{{ $t("other_programs") }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="list-group">
                    <template v-for="(program, key) in programs">
                        <a href="#" :class="'list-group-item list-group-item-action' +
                            (program.id == activeProgram.id
                                ? ' active'
                                : '')
                            " v-if="programs && programs.length > 1" @click="changeProgram(program.id)" :key="key">
                            {{ program.program_name }}
                        </a>
                    </template>
                </div>
            </template>
        </CommonModal>
        <CommonModal id="changePasswordModal" :onHideOnlyX="true" @bHideModalHeaderClose="changePasswordModalClose">
            <template v-slot:CommonModalTitle>
                <div>{{ $t("change_your_password") }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <change-password v-if="formProcess == 'change-password'"/>
            </template>
        </CommonModal>
    </header>
</template>
<script>
// Component
import ChangePassword from "@/modules/auth/pages/ChangePassword";
import CommonModal from "@/components/elements/CommonModal";


// Services
import UserService from "@/services/UserService";

// Other
import {localeChanged} from "vee-validate";



export default {
    components: {
        ChangePassword,
        CommonModal,
    },
    props: {
        selectedMenu: {},
        title: {
            type: String,
            default: null,
        },
        isNewButton: {
            type: Boolean,
            default: false,
        },
        isRankButton: {
            type: Boolean,
            default: false,
        },
        isImportButton: {
            type: Boolean,
            default: false,
        },
        isColumns: {
            type: Boolean,
            default: false,
        },
        isFilter: {
            type: Boolean,
            default: true,
        },
        isExpandAll: {
            type: Boolean,
            default: false,
        },
        isCollapseAll: {
            type: Boolean,
            default: false,
        },
        isCancelToMove: {
            type: Boolean,
            default: false,
        },
        asalButton: {
            type: Object,
            default: () => ({
                selected: null,
                show: false,
                icon: null,
                title: null,
                emit: null,
            }),
        },
        otherButton: {
            type: Object,
            default: () => ({
                show: false,
                icon: null,
                title: null,
                emit: null,
            }),
        },
        actions: {
            type: Array,
            default: null,
        },
        isBackButton: {
            type: Boolean,
            default: false,
        },
        isPrint: {
            type: Boolean,
            default: false,
        },
        backButton: {
            type: Function,
            default: function () {
            },
        },
    },
    data() {
        return {
            userPhoto: null,

            // Filter
            filterDivStatus: true,

            // Form Process
            formProcess: null,
        };
    },
    computed: {
        backVisibleState() {
            if (["/dashboard"].includes(this.$route.path)) {
                return false
            }
            return true
        },
        socialMediaLinks() {
            const socialMedia = this.$store.getters["socialMediaLinks"];
            let data = [];
            const platforms = [
                {
                    name: 'MsTeams',
                    svg: require('@/assets/svg/msteams.svg'),
                    title: 'MsTeams',
                    alt: 'MsTeams'
                },
                {
                    name: 'Tiktok',
                    svg: require('@/assets/svg/tiktok.svg'),
                    title: 'Tiktok',
                    alt: 'Tiktok'
                },
                {
                    name: 'Instagram',
                    svg: require('@/assets/svg/instagram.svg'),
                    title: 'Instagram',
                    alt: 'Instagram'
                },
                {
                    name: 'Twitter',
                    svg: require('@/assets/svg/twitter.svg'),
                    title: 'Twitter',
                    alt: 'Twitter'
                },
                {
                    name: 'Facebook',
                    svg: require('@/assets/svg/facebook.svg'),
                    title: 'Facebook',
                    alt: 'Facebook'
                },
                {
                    name: 'LinkedIn',
                    svg: require('@/assets/svg/linkedln.svg'),
                    title: 'LinkedIn',
                    alt: 'LinkedIn'
                },
                {
                    name: 'Youtube',
                    svg: require('@/assets/svg/youtube.svg'),
                    title: 'Youtube',
                    alt: 'Youtube'
                },
            ];
             socialMedia[0]?.items.forEach(itm => {
                const platform = platforms.find(p => p.name == itm.name);
                if (platform) {
                    data.push({
                        svg: platform.svg,
                        title: platform.title,
                        alt: platform.alt,
                        url: itm.code
                    });
                }
            });

            return data;
        },
        // Locale
        locale() {
            return this.$store.getters["getLocale"];
        },

        // Notifications
        notifications() {
            return this.$store.getters["auth/getNotifications"];
        },

        // Layout
        sideBarExpandedWrapper() {
            return this.$store.getters.getAppLayoutSideBarExpandedWrapper;
        },
        nightMode() {
            return this.$store.getters.getNightMode;
        },

        // User
        user() {
            return this.$store.getters["auth/getUser"];
        },

        // Photo
        photo() {
            let photo = this.$store.getters["auth/getPhoto"];
            if (photo) {
                return "'" + photo + "'";
            }
            return null;
        },

        // Student
        isStudent() {
            return this.$store.getters["auth/isStudent"];
        },

        // Roles
        roles() {
            let user = this.$store.getters["auth/getUser"];
            if (
                user &&
                user.roles &&
                user.roles.length > 1 &&
                user.person
            ) {
                return user.roles;
            }
            return [];
        },
        activeRole() {
            let user = this.$store.getters["auth/getUser"];
            if (user && user.active_role) {
                return user.active_role;
            }
            return null;
        },

        // Programs
        programs() {
            let user = this.$store.getters["auth/getUser"];
            if (user && user.student && user.student.student_program) {
                return user.student.student_program;
            }
            return [];
        },
        activeProgram() {
            let user = this.$store.getters["auth/getUser"];
            if (user && user.active_student_program) {
                return user.active_student_program;
            }
            return null;
        },

        // Filtered Actions
        filteredActions() {
            let arr = [];
            this.actions.forEach((item) => {
                let add = false;
                if (
                    !item.permission ||
                    (item.permission && this.checkPermission(item.permission))
                ) {
                    add = true;
                }
                if (add && typeof item.show == "function") {
                    add = item.show();
                }
                if (add) {
                    arr.push(item);
                }
            });
            return arr;
        },
    },
    methods: {
        goToSocialMedia(url){
          window.open(url,'blank')
        },
        // Template
        changeLocale(locale) {
            this.$i18n.locale = locale;
            this.$store.commit("setLocale", locale);
            localeChanged();
        },
        changeNightMode() {
            this.$store.commit("changeNightMode");
        },
        changeSideBarExpandedWrapper() {
            this.$store.commit("changeAppLayoutSidebarExpandedWrapper");
        },

        // Role
        changeRole(role) {
            if (parseInt(role.id) > 0) {
                UserService.changeRole(role)
                    .then(() => {
                        UserService.usersMe().then((responseUser) => {
                            let data = responseUser.data.data;
                            this.$store.commit("auth/setUser", data);
                        });
                    })
                    .then(() => {
                        UserService.menu().then((responseUser) => {
                            let data = responseUser.data.data;
                            this.$store.commit("auth/setMenus", data);
                        });
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    })
                    .finally(() => {
                        this.$bvModal.hide("otherIdenties");
                        this.$router.push("/");
                    });
            }
        },

        changeTitle(title) {
            title = title.replace(/i/g, 'ı')
            return title
        },
        // Program
        changeProgram(programId) {
            if (parseInt(programId) > 0) {
                UserService.changeProgram(programId)
                    .then(() => {
                        UserService.usersMe().then((responseUser) => {
                            let data = responseUser.data.data;
                            this.$store.commit("auth/setUser", data);
                        });
                    })
                    .catch((e) => {
                        this.showErrors(e);
                    })
                    .finally(() => {
                        this.$bvModal.hide("otherIdenties");
                        this.$router.push("/");
                    });
            }
        },

        // Filter
        filterDivStatusHide() {
            this.filterDivStatus = !this.filterDivStatus;
            this.$emit("filter-div-status", this.filterDivStatus);
        },

        // Change Password
        changePasswordModalShow() {
            this.formProcess = "change-password";
            this.$bvModal.show("changePasswordModal");
        },
        changePasswordModalClose() {
            (this.formProcess = null),
                this.$bvModal.hide("changePasswordModal");
        },

        // Print
        async print() {
            const config={
                autoClose:true
            }
            await this.$htmlToPaper("print-content",config);
        },
    },
    mounted() {
        this.$emit("filter:show", this.filterDivStatus);
    },
    created() {
        this.$store.dispatch('fetchSocialMediaLinks');
    }

};
</script>

<style lang="scss">
.header-back-icon {
    font-size: 2rem;
    cursor: pointer;
}

@media screen and (min-width: 768px) {
    .header-user-info-btn {
        line-height: normal !important;
    }
}

.social-media-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
}

.icons {
    margin-right: 10px;
    font-size: 20px;
}

.icons:hover {
    cursor: pointer;
    transition: all 2ms;
}

</style>
